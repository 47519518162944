import React, { useCallback, useEffect, useState } from 'react';
import InheritanceInput from "../InheritanceInput";
import { setInheritanceYearYou } from "../../../../../store/actions/savingsAndInvestments";
import { connect } from "react-redux";

const InheritanceYearYou = ( props ) => {
    const [value, setValue] = useState(props.inheritanceYear || 0);
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('Value must be next year or later');

    const validation = useCallback((val) => {
        const currentYear = new Date().getFullYear();
        if(val <= currentYear){
            setError(true);
            setErrorMessage( 'Value must be next year or later')
        }else if(val>2080){
            setError(true);
            setErrorMessage( 'Value can not be greater than 2080')
        }else {
            setError(false)
        }
    }, [])

    const onChange = e => {
        const value = +e.target.value.replace(/\$|\s/g, '');
        setValue(value)
        validation(value)
        props.setInheritanceYearYou(value)
    };

    useEffect(()=> {
        validation(props.inheritanceYear)
    }, [props, validation]);

    return (
        <InheritanceInput
            title={'Year'}
            onChange={onChange}
            error={error}
            value={value}
            errorMessage={errorMessage}
            type='year'
        />
    )
};

function mapStateToProps(state) {
    return {
        inheritanceYear: state.savingsAndInvestments.you.inheritance.inheritanceYear
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setInheritanceYearYou: (val) => dispatch(setInheritanceYearYou(val))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InheritanceYearYou);